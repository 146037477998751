<template>
  <div>
    <b-row>
      <b-col cols="10">
        <v-select
          v-if="renderSelect"
          id="vSelectHiringManager"
          v-model="selected_hiring_managers"
          :multiple="multiple"
          :placeholder="placeholder"
          :options="all_hiring_managers"
          :class="validator && $isNotEmpty(selected_hiring_managers) && $isNotEmpty(selected_hiring_managers.hiring_manager) ? '': 'danger-select' "
          label="hiring_manager"
          @search="hiringManagerSearch"
        />
      </b-col>
      <b-col cols="2">
        <b-button
          v-b-tooltip.hover.top="'Click this button to add a new hiring manager account, if they do not exist in the system.'"
          target="hr-adder"
          size="lg"
          variant="warning"
          class="btn-icon"
          title="Add Hiring Manager"
          @click="openCreateHRForm"
        >
          <feather-icon icon="UserPlusIcon" />
        </b-button>

      </b-col>
    </b-row>

    <b-modal
      id="companyModal"
      v-model="popupAddNewHR"
      centered
      hide-footer
      title="Add New Hiring Manager"
    >
      <div class="d-flex flex-column flex-gap-md">
        <div class="d-flex flex-row flex-gap-md align-items-center mt-1">
          <label style="width: 25ch;">Full Name</label>

          <b-form-input
            v-model="hiringManagerData.fullname"
            class="form-control-merge"
            placeholder="Input the fullname of the hiring manager"
          />
        </div>

        <div class="d-flex flex-row flex-gap-md align-items-center mt-1">
          <label style="width: 25ch;">Email Address</label>

          <b-form-input
            v-model="hiringManagerData.email_address"
            class="form-control-merge"
            placeholder="Input the email address of the hiring manager"
          />
        </div>

        <div class="d-flex flex-row flex-gap-md align-items-center mt-1">
          <label style="width: 25ch;">Company Name</label>

          <b-form-input
            v-model="companyRecord.company_name"
            class="form-control-merge"
            disabled
            placeholder="Input the company name of the hiring manager"
          />
        </div>

        <div class="d-flex justify-content-center mt-2">
          <b-button
            variant="primary"
            @click="createHiringManager"
          >{{ createHRText }}</b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>

import { debounce } from 'lodash'
import VSelect from 'vue-select'
import { VBPopover } from 'bootstrap-vue'

export default {
  components: {
    VSelect,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    placeholder: { type: String, default: 'Select the hiring manager' },
    companyRecord: { type: Object, default: null },
    stateErrors: { type: Array, default: null },
    multiple: { type: Boolean, default: false },
    allowAddNewCompany: { type: Boolean, default: false },
    validator: { type: Boolean, default: false },
    clearSelect: { type: Boolean, default: false },
  },
  data() {
    return {
      showHrAdder: false,
      all_hiring_managers: [],
      selected_hiring_managers: {},
      renderSelect: true,
      popupAddNewHR: false,
      hiringManagerData: {
        fullname: '',
        email_address: '',
        company_id: '',
        event_id: '',
      },
      createHRText: 'Create Hiring Manager Account',
    }
  },
  watch: {
    selected_hiring_managers(val) {
      if (
        this.$isEmpty(val.hiring_manager)
          && val.hiring_manager === 'Create hiring manager account'
      ) {
        this.popupAddNewHR = true
      }
      this.$emit('hiring-manager-selected', val)
    },
    clearSelect(val) {
      if (val) {
        this.selected_hiring_managers = this.multiple ? [] : {}
      }
    },
  },
  mounted() {
    this.selected_hiring_managers = this.multiple ? [] : {}
    let eventId = this.$route.params.event_id
    if (eventId && eventId.includes('-N')) {
      eventId = eventId.replace('-N', '')
    } else if (eventId && eventId.includes('_job')) {
      const jobId = eventId.replace('_job', '')
      eventId = null
      this.hiringManagerData.job_id = jobId
    }
    this.hiringManagerData.event_id = eventId
  },

  methods: {
    openCreateHRForm() {
      if (this.companyRecord.company_id) {
        this.popupAddNewHR = true
        this.hiringManagerData.company_id = this.companyRecord.company_id
      } else {
        this.$toastDanger('Hiring Manager Error', 'Select the company before creating the hiring manager account')
      }
    },

    hiringManagerSearch(search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchForHiringManagers(loading, search, this)
      }
    },

    searchForHiringManagers: debounce((loading, search, vm) => {
      vm.getHiringManagers(search, loading)
    }, 1000),

    getHiringManagers(query, loading = null) {
      this.all_hiring_managers = []

      const params = {}
      if (['applicant-tracking-system-edit', 'applicant-tracking-system-edit-specific'].includes(this.$route.name)) {
        params.section = 'ats'
      } else {
        params.section = 'ems'
      }

      if (this.companyRecord.company_id) {
        this.$http
          .get(`/api/hiring-manager-search/${this.companyRecord.company_id}/${query}?params=${JSON.stringify(params)}`)
          .then(response => {
            this.all_hiring_managers = []
            const record = response.data

            if (record.success) {
              record.data.map(data => {
                const item = {
                  fullname: data.fullname,
                  email_address: data.email_address,
                  alias_email_address: data.alias_email_address,
                  id: data.id,
                  hiring_manager: `${data.fullname} - ${data.email_address}`,
                }
                this.all_hiring_managers.push(item)
              })
              this.showHrAdder = false
            }
            if (this.$isEmpty(this.all_hiring_managers)) {
              const routeNameList = ['edit-event']
              if (this.allowAddNewCompany && routeNameList.includes(this.$route.name)) {
                this.renderSelect = false
                setTimeout(() => {
                  this.renderSelect = true
                }, 1)
                setTimeout(() => {
                  document.querySelector('#vSelectHiringManager input.vs__search').focus()
                }, 5)
              }
            }

            if (loading) {
              loading(false)
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      } else {
        this.$toastDanger('Hiring Manager Error', 'Select the company before specifying the hiring manager')
      }
    },

    createHiringManager() {
      this.createHRText = 'Creating....'

      if (['applicant-tracking-system-edit', 'applicant-tracking-system-edit-specific'].includes(this.$route.name)) {
        this.hiringManagerData.section = 'ats'
      } else {
        this.hiringManagerData.section = 'ems'
      }

      this.$http
        .post('/api/hiring-manager', {
          hr_manager_info: this.hiringManagerData,
        })
        .then(response => {
          const { success, output } = response.data
          if (success) {
            this.$toastSuccess('Operation Status', 'Hiring manager account created successfully!')

            const hrInfo = {
              fullname: output.fullname,
              email_address: output.email_address,
              alias_email_address: output.alias_email_address,
              id: output.id,
              hiring_manager: `${output.fullname} - ${output.email_address}`,
            }

            // this.$set(this, 'all_hiring_managers', [hr_info])
            this.$set(this, 'selected_hiring_managers', hrInfo)
            this.$set(this.selected_hiring_managers, 'hiring_manager', `${output.fullname} - ${output.email_address}`)
            this.$emit('hiring-manager-selected', hrInfo)

            this.popupAddNewHR = false
          } else {
            this.createHRText = 'Create Hiring Manager Account'
            this.$toastDanger('Something went wrong', response.data.message)
          }
        })
        .catch(error => {
          this.createHRText = 'Create Hiring Manager Account'
          this.$handleErrorResponse(error)
        })
    },

  },
}
</script>
